import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import config from '../../utils/siteConfig'

import _ from 'lodash'

import Link from '../../components/Link'

import { AlbumCard } from '../../components/Card/index'
import HeroNobackground from '../../components/HeroNoBackground'
import SEO from '../../components/SEO'
import { media } from '../../utils/media'
import { MainLayout } from '../../components/MainLayout'
import { space } from '../../utils/tokens'

const ScrollMarker = styled.div`
  position: absolute;
  top: 0px;
  bottom: 1.5rem;
  right: 0;
  height: 100%;
  width: 30px;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255, 1) 100%);
`

const CategoryTitle = styled.h4`
  font-size: 1.5rem;
  text-transform: capitalize;
  color: ${props => props.theme.colors.base}c4;
  margin-bottom: 1rem;
  font-weight: 500;
  margin-left: 1rem;
  margin-bottom: 1rem;
  ${media.desktop`
    padding: 0 4rem;
    margin-bottom: 2rem;
  `}

  ${media.ultrahd`
    max-width: 1880px;
    margin: auto;
    margin-bottom: 2rem;
  `}
`

const AlbumGroup = styled.div`
  display: flex;
  flex-shrink: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding: 0 1rem;

  &::after {
    content: '';
    padding-right: 3rem;
  }

  ${media.desktop`
    padding: 0 4rem;
  `}

  ${media.ultrahd`
    max-width: 1880px;
    margin: auto;
  `}

  > * {
    flex-shrink: 0;
    flex-grow: 0;
    width: 200px;
    margin-right: 1.5rem;

    ${media.desktop`
      width: 300px;
    `}
  }
`

const Gallery = ({ data }) => {
  const { hero, slug, title } = data.contentfulPage
  const postNode = data.contentfulPage

  const featuredAlbums = data.featuredAlbums.edges

  const groupedAlbums = _.groupBy(data.allContentfulAlbum.edges, ({ node }) => {
    return node.category
  })

  return (
    <div>
      <MainLayout>
        <Helmet>
          <title>{`${title} - ${config.siteTitle}`}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <SEO pagePath={slug} postNode={postNode} pageSEO />
        <section
          css={css({
            paddingTop: `${space[5]}px`,
            paddingBottom: `${space[5]}px`,
          })}
        >
          <HeroNobackground hero={hero} />
        </section>
        <div>
          <div
            css={css`
              margin-bottom: 2rem;

              ${media.desktop`
                  margin-bottom: 4rem;
                `}
            `}
          >
            <CategoryTitle>Featured</CategoryTitle>
            <div>
              <AlbumGroup>
                {featuredAlbums &&
                  featuredAlbums.map(({ node }) => {
                    return (
                      <Link to={node.fields.path} key={node.id}>
                        <AlbumCard
                          coverPhoto={node.coverPhoto}
                          title={node.title}
                        />
                      </Link>
                    )
                  })}
              </AlbumGroup>
            </div>
          </div>
          {Object.keys(groupedAlbums).map((category, rowIndex) => {
            const albums = groupedAlbums[category]
            return (
              <div
                key={rowIndex}
                css={css`
                  margin-bottom: 2rem;

                  ${media.desktop`
                      margin-bottom: 4rem;
                    `}
                `}
              >
                <CategoryTitle>
                  {category !== 'null' ? category : 'other'}
                </CategoryTitle>
                <div
                  css={css`
                    position: relative;
                  `}
                >
                  <AlbumGroup>
                    {albums &&
                      albums.map(({ node }, index) => {
                        return (
                          <Link to={node.fields.path} key={node.id}>
                            <AlbumCard
                              coverPhoto={node.coverPhoto}
                              title={node.title}
                            />
                          </Link>
                        )
                      })}
                  </AlbumGroup>
                  <ScrollMarker />
                </div>
              </div>
            )
          })}
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              ${media.desktop`
                  padding: 0 4rem;
                  margin-bottom: 2rem;
                `}

              ${media.ultrahd`
                  max-width: 1880px;
                  margin: auto;
                  margin-bottom: 2rem;
                `}
            `}
          >
            <h4
              css={css`
                font-size: 1.5rem;
                text-transform: capitalize;
                color: ${props => props.theme.colors.base}c4;
                margin-bottom: 1rem;
                font-weight: 500;
                margin-left: 1rem;
                margin-bottom: 1rem;

                ${media.desktop`
                    margin-bottom: 2rem;
                  `}

                ${media.ultrahd`
                    max-width: 1880px;
                    margin: auto;
                    margin-bottom: 2rem;
                `}
              `}
            >
              All images
            </h4>
            <div
              css={css`
                margin-left: 1rem;
                margin-bottom: 1rem;

                ${media.desktop`
                    margin: unset;
                  `}
              `}
            >
              <Link to={`/gallery/all-images/`}>
                <div
                  css={css`
                    height: 100%;
                    width: 100%;
                    box-shadow: 0 2px 26px rgba(0, 0, 0, 0.15);
                    border: 1px solid #efefef;
                    background-color: ${props => props.theme.colors.white};
                    border-radius: 10px;
                    color: ${props => props.theme.colors.base};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 200px;
                    height: 300px;

                    ${media.desktop`
                          width: 300px;
                          height: 200px;
                        `}
                  `}
                >
                  <span
                    css={css`
                      font-size: 2rem;
                      font-weight: bold;
                    `}
                  >
                    All
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  )
}

export default Gallery

export const galleryPageQuery = graphql`
  query GalleryPageQuery {
    contentfulPage(slug: { eq: "gallery"}) {
      hero { 
        ...HeroFragment_withNoBackground
      }
      title
      slug
      metaDescription {
        internal {
          content
        }
      }
    }
    featuredAlbums: allContentfulAlbum(filter: { isFeatured: { eq: true }}) {
      edges {
        node {
          title
          id
          category
          coverPhoto {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          fields {
            path
          }
        }
      }
    }
    allContentfulAlbum(sort: { fields: [title, category]}) {
      edges {
        node {
          title
          id
          category
          coverPhoto {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          fields {
            path
          }
        }
      }
    }
  }
`
